import React, { Fragment, useContext } from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql }  from 'gatsby'
import ds from '../theme'
import IcoStorage from '@material-ui/icons/Storage'
import IcoBrightness from '@material-ui/icons/Brightness4'
import IcoAcUnit from '@material-ui/icons/AcUnit'
import IcoEq from '@material-ui/icons/GraphicEq'
import IcoVpn from '@material-ui/icons/VpnLock'
import IcoNetwork from '@material-ui/icons/NetworkCheck'
import Check from '@material-ui/icons/CheckCircle'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Body2 from './../components/Body2'
import Div from './../components/Div'
import Body1 from './../components/Body1'
import { css } from '@emotion/core'

import H3 from '../components/H3'
import H5 from '../components/H5'
import Paragraph from '../components/Paragraph'
import DCSaoPaulo from '../images/data-centers/dc-sao-paulo.jpg'
import DCSaoPaulo2 from '../images/data-centers/dc-sao-paulo-2.jpg'
import DCSaoPaulo3 from '../images/data-centers/dc-sao-paulo-3.jpg'
import DCSaoPaulo4 from '../images/data-centers/dc-sao-paulo-4.jpg'
import DCSaoPaulo5 from '../images/data-centers/dc-sao-paulo-5.jpg'

import { withSolutions, SolutionContext } from '../components/SolutionProvider';
import { Container, mq } from '../components/styles/Layout.styles'
import { 
  styledDataCenters,
  styleEstructure,
  header,
  imagesRow
} from '../components/styles/DataCenters.styles'

const DcItem = ({
  img,
  title,
  children,
  icon: Icon,
  src,
  id
}) => {
  const [active, setIsActive] = useContext(SolutionContext)
  const isActive = active === id
  return (
    <div
      onClick={() => setIsActive(id)}
      style={{
        padding: 24,
        backgroundColor: '#FFFFFF',
        borderRadius: 16
      }}
      css={
        css(
          mq({
            '&:not(:last-child)': {
              marginBottom: ds.space(3)
            } 
          })
        )
      }
    >
      <Div
        flexDirection='row'
        alignItems='center'
      >
        <div
          style={{
            width: 68,
            height: 68,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            borderRadius: '50%',
            backgroundColor: '#f3f5f5',
            marginRight: ds.space(3)
          }}
        >
          {Icon && <Icon
            style={{
              color: 'rgba(0, 0, 0, 0.38)'
            }}
          />}
          {src && <img
            src={src}
          />}
        </div>
        <Body1
          selected
          on='onSurface'
          variant='highEmphasis'
          style={{
            width: 170,
            lineHeight: 1.46,
            letterSpacing: 0.5
          }}
        >
          {title}
        </Body1>
      </Div>
      {children && (
        <Div
          style={{
            position: 'relative',
            opacity: isActive ? 1 : 0,
            maxHeight: isActive ? 700 : 0,
            transition: isActive ? 'max-height 2s, opacity 0.35s 0.35s' : 'max-height 0.8s 0.35s, z-index 0.7s 0.7s, opacity 0.7s',
            zIndex: isActive ? 1 : -1
          }}
        >
          <Body2
            color='black'
            variant='mediumEmphasis'
            mt={[3]}
          >
            {children}
          </Body2>
        </Div>
      )}
    </div>
  )
}

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <Fragment>
      <section css={styledDataCenters}>
        <Container>
          <Row
            flexDirection={['column', 'row']}
            css={header}
          >
            <Column
              width={[1]}
            >
              <H3>
                <span>ESTRUTURA</span>
                Data Center São Paulo
              </H3>
              <Paragraph>A HostDime está presente nas localidades mais estratégicas para atender você. No Brasil, também temos instalações em São Paulo, principal centro financeiro e corporativo da América do Sul.</Paragraph>
            </Column>
          </Row>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'row', 'row']}
            mt={[40]}
          >
            <Column
              mb={[8]}
              px={[8, 8, 0, 0]}
            >
              <Div
                maxWidth={['100%', '180px', '304px']}
              >
                <img src={DCSaoPaulo} css={imagesRow} />
              </Div>
            </Column>
            <Column
              mb={[8]}
              px={[8, 8, 0, 0]}
              mx={[0, 0, 8, 8]}
            >
              <Div
                maxWidth={['100%', '180px', '304px']}
              >
                <img src={DCSaoPaulo2} css={imagesRow} />
              </Div>
            </Column>
            <Column
              mb={[8]}
              px={[8, 8, 0, 0]}
            >
              <Div
                maxWidth={['100%', '180px', '304px']}
              >
                <img src={DCSaoPaulo3} css={imagesRow} />
              </Div>
            </Column>
          </Row>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'row']}
          >
            <Column
              mb={[8, 8, 8, 0]}
              px={[8, 8, 0, 0]}
              mr={[0, 0, 4, 4]}
            >
              <Div
                maxWidth={['100%', '100%', '100%', '100%']}
              >
                <img src={DCSaoPaulo4} />
              </Div>
            </Column>
            <Column
              mb={[8, 8, 8, 0]}
              px={[8, 8, 0, 0]}
              ml={[0, 0, 4, 4]}
            >
              <Div
                maxWidth={['100%', '100%', '100%', '100%']}
              >
                <img src={DCSaoPaulo5} />
              </Div>
            </Column>
          </Row>
        </Container>
      </section>
      <section css={styleEstructure}>
        <Container>
          <Row
            justifyContent={'center'}
            pt={40}
            pb={30}
          >
            <Column>
              <H5>Saiba mais sobre<br /> nossa estrutura</H5>
            </Column>
          </Row>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'row']}
            pb={50}
          >
            <Column
              mb={[8, 8,  8,  0]}
            >
              <Div
                maxWidth={['100%', '100%', 422]}
              >
                <DcItem
                  icon={IcoStorage}
                  title='Espaço e Infraestrutura'
                  id='solution01'
                >
                  <Div
                    width={['100%', '100%', '360px']}
                  >
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      6.000 metros quadrados
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Piso elevado com 1m de altura
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Certificado TIER-3
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Certificado SSAE-16 / ISAE 3402
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      ISO 9001
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      ISO 27001
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      PCI-DSS
                    </Div>
                    <Div
                      flexDirection={['row']}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      ISO 20000
                    </Div>
                  </Div>
                </DcItem>
                <DcItem
                  icon={IcoAcUnit}
                  title='Poder de Alta Disponibilidade'
                  id='solution02'
                >
                  <Div
                    width={['100%', '100%', '360px']}
                  >
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      12 MW de energia direta disponível
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Distribuição por painéis eletrônicos duplos
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Sistema UPS com capacidade de até 11,8 MW
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Geradores redundantes com reserva de 60 mil litros de óleo diesel
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      14 MW de capacidade dos geradores, que assumem a operação em apenas 30 segundos
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Capacidade de funcionamento em plena eficiência por 200 horas sem reabastecimento
                    </Div>
                    <Div
                      flexDirection={['row']}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Testes de rotina e manutenção dos sistemas
                    </Div>
                  </Div>
                </DcItem>
                <DcItem
                  icon={IcoBrightness}
                  title='Refrigeração de Alta Eficiência'
                  id='solution03'
                >
                  <Div
                    width={['100%', '100%', '360px']}
                  >
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Capacidade de até 1.920 toneladas de refrigeração
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Sistema de combate à incêndio via gás inerte FM-200
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Equipamentos de ar-condicionado de expansão direta com capacidade para até 30 toneladas cada
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Sistema precoce de detecção de incêndios VESDA
                    </Div>
                    <Div
                      flexDirection={['row']}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Sistema de combate à incêndio via gás inerte FM-200
                    </Div>
                  </Div>
                </DcItem>
              </Div>
            </Column>
            <Column
              mt={[8, 8, 0, 0]}
            >
              <Div
                maxWidth={['100%', '100%', 422]}
              >
                <DcItem
                  icon={IcoEq}
                  title='Monitoramento Efetivo'
                  id='solution04'
                >
                  <Div
                    width={['100%', '100%', '360px']}
                  >
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Controles e monitoramento de missão crítica de última geração
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Patente HD-World DCIM que fornece o monitoramento em tempo real e relatórios de entrega de energia, sistemas de refrigeração e condições ambientais de piso de dados para maximizar a otimização, tempo de atividade e a eficiência operacional
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Gerenciamento e planejamento eficazes da capacidade
                    </Div>
                    <Div
                      flexDirection={['row']}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Portal do cliente Core com dados instantâneos de utilização de energia 24x7, gerenciamento de chamados, relatórios de utilização de largura de banda e gerenciamento de crachás de acesso a contas de clientes
                    </Div>
                  </Div>
                </DcItem>
                <DcItem
                  icon={IcoVpn}
                  title='Segurança Impenetrável'
                  id='solution05'
                >
                  <Div
                    width={['100%', '100%', '360px']}
                  >
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Técnicos operando 24x7x365
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Acesso remoto fulltime por nossos times de São Paulo, Nordeste e Orlando-FL
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Segurança eletrônica com controle de acesso físico
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Entrada tipo “gaiola” com diferentes níveis de segurança
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Câmeras de vigilância com longo alcance
                    </Div>
                    <Div
                      flexDirection={['row']}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Porta automática com detector de metais
                    </Div>
                  </Div>
                </DcItem>
                <DcItem
                  icon={IcoNetwork}
                  title='Conectividade e Largura de banda'
                  id='solution06'
                >
                  <Div
                    width={['100%', '100%', '360px']}
                  >
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Conexão direta ao PTT-SP
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Carrier Neutral
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Backbone Telefônica
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Backbone Global Crossing
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Backbone Algar Telecom
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Backbone Embratel
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Backbone Oi
                    </Div>
                    <Div
                      flexDirection={['row']}
                      style={{
                        marginBottom: 20
                      }}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Backbone AES
                    </Div>
                    <Div
                      flexDirection={['row']}
                    >
                      <Check 
                        style={{
                          color: '#50e3dc',
                          marginRight: 16
                        }}
                      />
                      Conectado ao NAP of the Americas
                    </Div>
                  </Div>
                </DcItem>
              </Div>
            </Column>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo title='Data Center Nordeste' />
      <PageTemplate
        title='Data Center Nordeste'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "ilustra-o-home-1@3x.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default withSolutions(StaticPage)
